<template>
  <span v-if="hasRole">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    role: { type: String, default: '' }
  },
  computed: {
    hasRole () {
      return this.user_role && this.role.split(',').includes(this.user_role)
    },
    user_role () { // auth user user_role
        return this.$store.state.auth.user
        ? this.$store.state.auth.user.iam
        : null;
    }
  }
}
</script>