<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./withdraw_widget";
import WithdrawalHistory from "./withdrawalHistory";
import { getWithdrawalRecord } from '@/services/withdrawal.service'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    WithdrawalHistory,
  },
  data() {
    return {
      title: "Withdrawals",
      fetchingRecords: false,
      withdrawalHistory: [],
      totalAffiliateEarnings: 0,
    };
  },
  mounted() {
    this.fetchRecords()
  },
  computed: {
    totalPaidWithdrawal() {
      var total = this.withdrawalHistory.reduce((sum, withdrawal) => {
        return withdrawal.completed_at != null ? sum + parseFloat(withdrawal.amount) : sum + 0
      }, 0);
      return total;
    },
    totalPendingWithdrawal() {
      var total = this.withdrawalHistory.reduce((sum, withdrawal) => {
        return withdrawal.completed_at == null ? sum + parseFloat(withdrawal.amount) : sum + 0
      }, 0);
      return total;
    },
    totalAmountWithdrawal() {
      return this.totalAffiliateEarnings - this.totalPaidWithdrawal;
    }
  },
  methods: {
    updateWithdrawal(data) {
      var index = this.withdrawalHistory.findIndex(withdrawal => withdrawal.id == data.id);
      this.withdrawalHistory[index].completed_at = data.completed_at;
      this.withdrawalHistory[index].payment_details = data.payment_details;
    },
    async fetchRecords() {
      try {
        this.fetchingRecords = true;
        let response = await getWithdrawalRecord();
        console.log(response);
        this.withdrawalHistory = response.data.data.withdrawals;
        this.totalAffiliateEarnings = response.data.data.total_affiliate_earnings;
        // this.totalWithdrawals = response.data.data.total_withdrawals;
        // this.totalEarnings = response.data.data.total_earnings;

        this.fetchingRecords = false;
        console.log(response);
      } catch (error) {
        this.fetchingRecords = false;
        alert(error);
        console.log(error);
      }
    },
    addToWithdrawalHistory(withdrawal) {
      this.withdrawalHistory.push(withdrawal);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <b-overlay :show="fetchingRecords" rounded="md" opacity="0.8">
      <div class="row">
        <div class="col-md-12">
          <Stat @addToWithdrawalHistory="addToWithdrawalHistory" :totalPaidWithdrawal="totalPaidWithdrawal" :totalPendingWithdrawal="totalPendingWithdrawal" :totalAmountWithdrawal="totalAmountWithdrawal"/>
        </div>
      </div>
    </b-overlay>
    <div class="row">
      <div class="col-md-12">
        <WithdrawalHistory :withdrawalHistory="withdrawalHistory" :fetchingRecords="fetchingRecords" @updateWithdrawal="updateWithdrawal"/>
      </div>
    </div>
  </Layout>
</template>