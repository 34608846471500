import { http } from "./http.service";

export function getWithdrawalRecord() {
    return http().get("/withdrawals");
}

export function requestWithdrawal(withdrawal) {
    return http().post("/withdrawals", withdrawal)
}

export function markWithdrawalAsCompleted(withdrawalId, arg) {
    return http().put("/withdrawals/" + withdrawalId, arg)
}