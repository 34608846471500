<script>
import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import { requestWithdrawal } from "@/services/withdrawal.service";
import roleAccessControl from '../../role-access-control.vue';
/**
 * Widget Component
 */
export default {
  components: { roleAccessControl },
  page: {
    title: "Withdrawal",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      loading: false,
      withdrawal: {
        amount: "",
      },
      submitted: false,
      errors: {},
    };
  },
  props: {
    totalPendingWithdrawal: {
      type: Number,
      default: 0,
    },
    totalPaidWithdrawal: {
      type: Number,
      default: 0,
    },
    totalAmountWithdrawal: {
      type: Number,
      default: 0,
    },
  },
  validations: {
    withdrawal: {
      amount: { required, Number },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    async requestWithdrawal() {
      this.$store.dispatch("notification/clear");
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        try {
          this.errors = {};
          this.loading = true;
          var response = await requestWithdrawal(this.withdrawal);

          this.$store.dispatch("notification/success", response.data.message);
          this.loading = false;
          // send record to parent component for proper update
          this.$emit("addToWithdrawalHistory", response.data.data);
          this.withdrawal.amount = "";
          this.submitted = false;
          // this.$router.go();
        } catch (error) {
          this.loading = false;
          console.log(error);
          if (error.response) {
            switch (error.response.status) {
              case 422:
                this.errors = error.response.data.errors;
                break;
              case 401:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                break;
              case 403:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                break;
              case 500:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                break;
              default:
                this.$store.dispatch(
                  "notification/error",
                  "Something went wrong. Please try again!"
                );
                break;
            }
          }
        }
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <b-card class="text-white-50" style="background: #4c0410">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-16 mb-2 text-white">
                Pending Withdrawal
              </p>
              <h2 class="mb-0 font-size-30 text-white">
                {{ totalPendingWithdrawal | currencyFormat }}
              </h2>
            </div>
            <div class="text-primary">
              <i
                class="ri-briefcase-4-line"
                style="font-size: 40px; color: white"
              ></i>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-sm-12 col-md-6">
      <b-card style="background: #990000" class="text-white-50">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-16 mb-2 text-white">
                Paid Withdrawal
              </p>
              <h2 class="mb-0 text-white font-size-30">
                {{ totalPaidWithdrawal | currencyFormat }}
              </h2>
            </div>
            <div class="text-primary">
              <i class="ri-coin-line" style="font-size: 40px; color: white"></i>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-sm-12 col-md-12">
      <b-card style="background: rgb(68, 68, 68);" class="text-white-50">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-16 mb-2 text-white">
                Total Amount Withdrawable
              </p>
              <h2 class="mb-0 text-white font-size-30">
                {{ totalAmountWithdrawal | currencyFormat }}
              </h2>
            </div>
            <div class="text-primary">
              <i class="ri-coin-line" style="font-size: 40px; color: white"></i>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-1"></div>
    <div class="col-10">
    <roleAccessControl role='agent'>
      <b-card style="background: white" class="text-black-50">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <b-alert
                :variant="notification.type"
                class="mt-1"
                v-if="notification.message"
                show
                dismissible
                @dismissed="$store.dispatch('notification/clear')"
                >{{ notification.message }}</b-alert
              >
              <p class="font-size-14 mb-0 text-black" style="margin-left: 12px">
                Enter the amount without a comma (Example: 100000)
              </p>
              <div class="col-12">
                <form @submit.prevent="requestWithdrawal">
                  <div class="form-group row mb-3">
                    <div class="col-md-10" style="margin-top: 20px">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Enter Amount to withdraw"
                        v-model="withdrawal.amount"
                        :class="{
                          'is-invalid':
                            (submitted && $v.withdrawal.amount.$error) ||
                            errors.amount,
                        }"
                      />
                      <div
                        v-if="
                          (submitted && $v.withdrawal.amount.$error) ||
                          errors.amount
                        "
                        class="invalid-feedback"
                      >
                        <span v-if="errors.amount"
                          >{{ errors.amount[0] }}. &nbsp;</span
                        >
                        <span v-if="!$v.withdrawal.amount.required"
                          >This field is required &nbsp;</span
                        >
                        <span v-if="!$v.withdrawal.amount.Number"
                          >This field must be a number.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-2" style="margin-top: 20px">
                      <button class="btn btn-success">
                        Withdrawal
                        <b-spinner
                          v-show="loading"
                          small
                          class="align-middle"
                        ></b-spinner>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </roleAccessControl>
    </div>
    <div class="col-1"></div>
    <!-- end col -->
  </div>

  <!-- end row -->
</template>