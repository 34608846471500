<script>
/**
 * Transactions component
 */
import AccessControl from "@/views/access-control";
import { markWithdrawalAsCompleted } from "@/services/withdrawal.service";
import AgentDetailsModal from "@/views/agent-modal";

export default {
  props: {
    withdrawalHistory: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fetchingRecords: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  components: { AccessControl, AgentDetailsModal },
  data() {
    return {
      selectedUser: {},
      processing: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "completed_at",
      sortDesc: true,
      fields: [
        "#",
        {
          key: "created_at",
          sortable: true,
          formatter: (value) => {
            return this.$options.filters.dateFormat(value);
          },
        },
        {
          key: "payment_details",
          // formatter: (value, index, row) => {
          //   if (value == null) {
          //     if (row.agent.bank_name != null) {
          //       return (
          //         row.agent.account_name +
          //         "/" +
          //         row.agent.account_number +
          //         "/" +
          //         row.agent.bank_name +
          //         "/" +
          //         row.agent.username
          //       );
          //     } else {
          //       return row.agent.username;
          //     }
          //   } else {
          //     return value;
          //   }
          // },
        },
        {
          key: "amount",
          sortable: true,
          label: "Amount Paid",
          formatter: (value) => {
            return this.$options.filters.currencyFormat(value);
          },
        },
        { key: "completed_at", sortable: true, label: "Payment Status" },
        // 'action'
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.withdrawalHistory.length;
    },
    isBusy() {
      return this.fetchingRecords;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.withdrawalHistory.length;
    console.log(this.withdrawalHistory);
  },
  methods: {
    async approveWithdrawal(data) {
      if (
        !confirm(
          "Are you sure you have completed this withdrawal request?\nYou cannot undo this action"
        )
      ) {
        return;
      }

      if (data.agent.account_number == null) {
        alert("This agent has not updated his account details!");
        return;
      }

      var payment_details =
        data.agent.account_name +
        "/" +
        data.agent.account_number +
        "/" +
        data.agent.bank_name;
      try {
        this.processing = true;
        var response = await markWithdrawalAsCompleted(data.id, {
          payment_details,
        });
        console.log(response.data.data);
        this.$emit("updateWithdrawal", response.data.data);
        this.processing = false;
      } catch (error) {
        this.processing = false;
        console.log(error);
        switch (error.response.status) {
          case 500:
            this.$bvToast.toast(error.response.data.message, {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
          case 403 || 401:
            this.$bvToast.toast(error.response.data.message, {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
          default:
            this.$bvToast.toast(
              "[" +
                error.response.status +
                "] Something went wrong. Please try again!",
              {
                title: "Error!",
                variant: "danger",
                solid: true,
              }
            );
            break;
        }
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">

      <h4 class="card-title mb-4">Withdrawal Requests</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <b-overlay :show="processing" rounded="md" opacity="0.8">
      <div class="table-responsive">
        <b-table
          :items="withdrawalHistory"
          :fields="fields"
          responsive="sm"
          :busy="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
        >
        <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> &nbsp; Loading...</strong>
            </div>
          </template>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(completed_at)="row">
            <span
              class="badge font-size-12"
              :class="{'badge-soft-success': `${row.value}` != null,
              'badge-soft-warning': `${row.value}` === ''}"
            >{{ row.value ? 'paid' : 'pending' }} </span> &nbsp;

            <AccessControl permission="approve withdrawal">
              <span
                class="badge font-size-12 badge-primary"
                style="cursor: pointer"
                @click="approveWithdrawal(row.item)"
                v-if="row.item.completed_at == null"
              >
                Mark as paid
              </span>
            </AccessControl>
          </template>
          <template v-slot:cell(payment_details)="data">
            {{ data.item.payment_details }}
            <AccessControl permission="approve withdrawal">
              <a
              href="javascript:void(0);"
              class="mr-3 text-success"
              v-b-tooltip.hover
              @click="
                selectedUser = data.item.agent;
                $refs['agent-details-modal'].show();
              "
            >
              {{ data.item.agent.username }}
            </a>
            </AccessControl>
          </template>
        </b-table>
      </div>
      </b-overlay>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>

      
    <b-modal
      ref="agent-details-modal"
      size="md"
      centered
      scrollable
      title="User Info"
      title-class="font-18"
      hide-footer
    >
      <AgentDetailsModal :agent="selectedUser"/>
    </b-modal>
    </div>
  </div>